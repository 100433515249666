@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;700&display=swap");

:root {
  /* Color palette */
  --primary: #9b6a6c;
  --dark-brown: #332a06;
  --secondary: #e2b4bd;
  --accent: #424b54;
  --helper: #93a8ac;
  --white: #f8f5f5;
}

body {
  margin: 0;
  background-color: var(--white);
  font-family: "Comfortaa", cursive;
  transition: all 300ms;
}

a {
  text-decoration: none;
  border-bottom: 1px solid transparent;
  border-radius: 10px;
  padding: 0 5px 3px;
}

a:hover {
  border-color: var(--dark-brown);
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:active {
  background-color: var(--primary);
}
*::-webkit-scrollbar-track {
  background-color: #ddd;
}

.App {
  margin: 2em auto;
  width: 100%;
  max-width: 100vh;
  max-width: 1024px;
}

.App::-webkit-scrollbar {
  display: none;
}

.App::-webkit-scrollbar {
  display: none;
}

.header {
  color: var(--accent);
}

.header__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5em;
  color: var(--helper);
}

.header img {
  width: 4em;
}

.header__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.header__description a {
  padding: 0.5em 1em;
  margin-top: 30px auto;
  width: max-content;
  background-color: var(--secondary);
  color: white;
  display: block;
  font-size: 1.2em;
}

.GalleryContainer {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 40px;
  place-content: center;
}

.Art {
  background-color: transparent;
  width: 100%;
  height: 300px;
  overflow: hidden;
  perspective: 1000px;
  padding: 20px 0;
}

.Art__inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.Art__inner img {
  width: 100%;
  height: 100%;
}

.Art:hover .Art__inner {
  transform: rotateY(180deg);
}

.Art__front,
.Art__back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.Art__title {
  display: block;
  color: var(--white);
  font-size: 2em;
  margin: 40px auto 20px;
  color: var(--secondary);
  font-weight: bold;
  width: 90%;
}

.Art__tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Art__tag {
  background-color: var(--accent);
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 0.8em;
  margin-right: 5px;
  margin-bottom: 5px;
}

.Author__rrss {
  margin: 15px 0;
}

.Author__rrss a {
  color: var(--white);
  margin-right: 5px;
}

.Art__back {
  background-color: var(--primary);
  color: var(--white);
  transform: rotateY(180deg);
}

.Footer {
  padding-top: 80px;
  text-align: center;
  color: var(--accent);
}

.Footer a {
  color: var(--primary);
}

/*Dark Mode*/
.dark__mode {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background-color: #333;
  padding: .5rem;
}

.dark__mode-icon {
  display: block;
  width: 30px;
  transition: all 1s;
}

.dark-mode-colors {
  --primary: #9b6a6c;
  --dark-brown: #332a06;
  --secondary: #e2b4bd;
  --accent: #fff;
  --helper: #fff;
  --white: #232222;
}

.Filter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.Filter > span {
  margin: 20px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--accent);
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.filter-arts {
  cursor: pointer;
  display: block;
  margin: 2rem auto 0 auto;
  font-size: .9rem;
  min-width: 110px;
  height: 40px;
  max-width: 130px;
  border-radius:20px;
  background-color: var(--secondary);
  position: relative;
}

.filter-arts::after {
  display: block;
  content: "";
  background-image: url('./assets/arrow-down.svg');
  width: 15px;
  background-size: cover;
  height: 15px;
  position: absolute;
  top: 13px;
  right: 20px;
}

.filter-arts select {
  cursor: pointer;
  background-color: transparent;
  border:none;
  color: #fff;
  font-weight: bold;
  background-color: inherit;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  padding-left: 20px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.filter-arts select:focus {
  outline:none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--secondary);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media (max-width: 500px) {
  .header__logo {
    font-size: 1em;
  }
}
